import '../styles/pages/old.scss';
// import '../styles/pages/corp-onboard.scss';
import * as React from "react"
import {HeadFC, navigate, PageProps} from "gatsby"
import PageService, {HomePageComponents} from "../services/pageService";
import {useEffect, useMemo, useState, useRef} from "react";
import {
    BigLogoImage, Crown2, LoadingGif, arrRight2, lang
} from "../images";
import {ApiComponentType} from "../types/ApiComponent";
import HomeOverview from "../components/HomeOverview";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import MobileNavigation from '../components/MobileNavigation';
import HomeStatistics2 from '../components/HomeStatistics2';
import MainFooter3 from '../components/MainFooter3';
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../hooks/useAnalytics";
import { Link } from 'gatsby';
import LearnBlock2 from '../components/LearnBlock2';
import HeaderCorpSpec from '../components/HeaderCorpSpec';
import HomeOverviewSpecial from '../components/HomeOverviewSpecial';
import HomeScreenshots4 from '../components/HomeScreenshots4';
import LearnBlockList4 from '../components/LearnBlockList4';
import AIAeerSpecial from '../components/AIAeerSpecial';
import MainFooter4 from '../components/MainFooter4';
import LearnBlockSpecial from '../components/LearnBlockSpecial';

// type DataProps = {
//     path: string
// }

const CorpSpecialIlyaGooglePage = ({path}: PageProps) => {
    const [isLoading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [namePath, setNamePath] = useState('');
    const [data, setData] = useState<HomePageComponents>([]);
    const [showMenu, setShowMenu] = useState(false);
    const [email, setEmail] = useState('');
    const [formStatus, setFormStatus] = useState(false);
    const {initAnalytics, saveEmail} = useAnalytics();

    useEffect(() => {
        setNamePath(path.replaceAll('/', '-'));
    }, [data]);

    useEffect(() => {
        initAnalytics();
    }, []);

    const inputRef = useRef();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const showForm = (status) => {
        setFormStatus(status);
        status ? inputRef.current.focus() : null;
    }

    const onFormSubmit =async () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setFormLoading(true);
            await saveEmail(email);
            // fbq("track", "Subscribe");
            localStorage.setItem('email', email);
            // navigate('/corp-main-special/trial');
        } else {
            setFormLoading(false);
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    useEffect(() => {
        PageService.getCourseById(1).then((result) => {
            setData(result.data.data.attributes.Content)
        }).catch(() => {
        }).finally(() => {
            setLoading(false)
        });
    }, []);

    const renderContent = useMemo(() => {
        return data.map((content, index) => {
            switch (content.__component) {
                case ApiComponentType.HOME_OVERVIEW: {
                    return (
                        <HomeOverviewSpecial key={`item-${index}`} data={content} fullLink="https://aeer.onelink.me/MldY/sjhyb6ix"/>
                    )
                }

                // case ApiComponentType.HOME_INFO_BLOCK: {
                //     return (
                //         <HomeInfoBlock key={`item-${index}`} data={content}/>
                //     )
                // }

                // case ApiComponentType.LEARN_BLOCK_LIST: {
                //     return (
                //         <LearnBlock key={`item-${index}`} showForm={showForm} data={content} />
                //     )
                // }

                case ApiComponentType.LEARN_BLOCK_LIST: {
                    return (
                        <div key="learnBlockKey">
                            <LearnBlockList4 key={`learn-list-${index}`} data={content} pagePath={namePath} fullLink="https://aeer.onelink.me/MldY/sjhyb6ix" />
                            <AIAeerSpecial key={`ai-aeer-${index}`} data={content} />
                            <LearnBlockSpecial key={`item-${index}`}  fullLink="https://aeer.onelink.me/MldY/sjhyb6ix" />
                        </div>
                    )
                }

                case ApiComponentType.APP_SCREENSHOTS: {
                    return (
                        <HomeScreenshots4 key={`item-${index}`} data={content} fullLink="https://aeer.onelink.me/MldY/sjhyb6ix" pagePath={namePath} />
                    )
                }
            }
        })
    }, [data]);

    return (
        <main className="brownShadow">
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <HeaderCorpSpec toggleMenu={toggleMenu} pagePath={namePath} fullLink="https://aeer.onelink.me/MldY/sjhyb6ix" />

            <div className="content">

                {renderContent}

                {/* <div className="buttonBlock2" id={'preorder'}>
                    <Link to="/corp-main-onboard/quiz-how-to-call" className="btnv2 btn-primary">
                        <img src={Crown2} className="btn-img" width={30} alt=""/>
                        <span>Try AEER</span>
                        <img src={arrRight2} className="btn-img" width={30} alt=""/>
                    </Link>
                </div> */}

                <div className={`blurFormWrap ${formStatus ? 'active' : ''}`}>
                    <div className="blurFormOverlay" onClick={() => showForm(false)}></div>
                    <div className="blurForm">
                        <div className="blurFormTitle">Let's customize the aeer app for you</div>
                        <div className="blurForm">
                            <div className={`solutionsEmailInput`}>
                                <p className="solutionsEmailTitle">Enter your email to continue</p>
                                <label htmlFor="email">Email</label>
                                <input id="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="Please enter your email address"
                                    autoComplete="off"
                                    ref={inputRef} />
                                <button onClick={() => onFormSubmit()} className="solutionsBtn" id={`pageCorp${namePath}SignUpBtn`}>Sign Up</button>
                            </div>
                            <p className="solutionsSecure static">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <MainFooter /> */}
            <MainFooter4 pagePath={namePath} btnType={'appStore'} fullLink="https://aeer.onelink.me/MldY/sjhyb6ix" />

            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="Corp" />
            )}

            {formLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}

            <ToastContainer/>
        </main>
    )
}

export default CorpSpecialIlyaGooglePage;

export const Head: HeadFC = () => <title>AEER Platform</title>
